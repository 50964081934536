import * as React from 'react';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { shuffle } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const spring = {
  type: 'spring',
  damping: 20,
  stiffness: 300
};

const useStyles = makeStyles(theme => ({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
    display: 'flex',
    width: '60px',
    flexWrap: 'wrap'
  },
  li: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    borderRadius: '30%',
    marginBottom: '2px',
    marginRight: '2px',
    width: '25px',
    height: '25px'
  },
  blockText: {
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: '6%',
    color: 'white'
  },
  logoContainer: {
    textAlign: 'center'
  }
}));

export default function Logo(props) {
  const [colors, setColors] = useState(initialColors);
  const classes = useStyles();
  const { triggerUpdate } = props;

  // useEffect(() => {
  //   setTimeout(() => setColors(shuffle(colors)), 3000);
  // }, [colors]);

  useEffect(() => {
    function shuffleUntilNew(color) {
      const newColor = shuffle(color);
      if (newColor === color) {
        return shuffleUntilNew(color);
      }
      return newColor;
    }

    setColors(color => shuffleUntilNew(color));
    
  }, [triggerUpdate]);

  return (
    <div className={classes.logoContainer}>
      <ul className={classes.ul}>
        {colors.map((background, i) => (
          <motion.li
            className={classes.li}
            key={background}
            layoutTransition={spring}
            style={{ background }}
          >
            <Typography
              component="p"
              variant="body1"
              color="inherit"
              className={classes.blockText}
            >
              {blockText[i]}
            </Typography>
          </motion.li>
        ))}
      </ul>
    </div>
  );
}

// const initialColors = ['#e31b6d', '#143642', '#667687', '#5a40dd'];
// const initialColors = ['#e52f7a', '#ba175a', '#7c0f3c', '#3e081e'];
// const initialColors = ['#8dbf2f', '#678b23', '#415716', '#1a2309'];
// const initialColors = ['#e31b6d', '#ff5e9b', '#ab0043', '#000000'];
// const initialColors = ['#b72e00', '#b78900', '#5c00b7', '#000000'];
const initialColors = ['#1565c0', '#5e92f3', '#003c8f', '#000000'];
const blockText = ['O', 'W', 'E', 'N'];

// const initialBlock = [{color:"#FF008C", text:'O'}, {color:"#D309E1", text:'W'}, {color:"#9C1AFF", text: 'E'}, {color:"#7700FF", text: 'N'}];
