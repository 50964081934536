import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  // Styles cards for standard apps
  card: {
    width: 500,
    marginTop: '2em',
    display: 'flex',
    flexDirection: 'column'
  },
  // Styles cards for mobile apps
  cardMobile: {
    width: 350,
    marginTop: '2em',
    display: 'flex',
    flexDirection: 'column'
  },
  // Styling for both mobile and standard
  viewCodeButton: {
    marginLeft: 'auto',
    marginRight: '2em'
  },
  descriptionContainer: {
    marginLeft: '1em',
    marginRight: '1em',
    marginBottom: 'auto',
    paddingBottom: 0
  },
  viewCodeContainer: {
    marginTop: 'auto'
  }
}));

export default function ProjectCard(props) {
  const classes = useStyles();
  const { project, handleCardActionAreaClick } = props;

  return (
    <Card
      className={project.mobile ? classes.cardMobile : classes.card}
      // elevation={10}
    >
      <CardContent>
        <Typography gutterBottom variant="h6" component="h3">
          {project.title}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {project.technologies}
        </Typography>
      </CardContent>
      <CardActionArea onClick={() => handleCardActionAreaClick(project)}>
        <CardMedia
          component="img"
          alt={project.title}
          src={project.playing ? project.animation : project.image}
          title={project.title}
        />
      </CardActionArea>
      <CardContent className={classes.descriptionContainer}>
        <Typography variant="body2" component="p" align="left">
          {project.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.viewCodeContainer}>
        {project.frontEndCode && (
          <Button
            size="small"
            color="secondary"
            className={classes.viewCodeButton}
            onClick={() => window.open(project.frontEndCode, '_blank')}
          >
            Front End Code
          </Button>
        )}
        {project.backEndCode && (
          <Button
            size="small"
            color="secondary"
            className={classes.viewCodeButton}
            onClick={() => window.open(project.backEndCode, '_blank')}
          >
            Back End Code
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
