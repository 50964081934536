import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import '../icons/css/Glyphter.css';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '60%',
    position: 'fixed',
    bottom: 0,
    marginBottom: '1em',
  },
  skillsCard: {
    width: 450,
    marginTop: '2em',
    paddingTop: '1em',
  },
  list: {
    width: '50%',
    margin: 0,
  },
  listsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItemFE: {
    paddingLeft: '3em',
    width: '100%',
    '&:hover': {
      backgroundColor: '#E31B6D',
    },
  },
  listItemBE: {
    paddingLeft: '3em',
    width: '100%',
    '&:hover': {
      backgroundColor: '#00b4b7',
    },
  },
  listItemM: {
    paddingLeft: '3em',
    width: '100%',
    '&:hover': {
      backgroundColor: '#5438dc',
    },
  },
  break: {
    flexBasis: '100%',
  },
}));

const skills = {
  frontEnd: [
    { name: 'React', icon: 'devicon-react-original' },
    { name: 'Angular', icon: 'devicon-angularjs-plain' },
    { name: 'JavaScript', icon: 'devicon-javascript-plain' },
    { name: 'HTML5', icon: 'devicon-html5-plain' },
    { name: 'CSS3', icon: 'devicon-css3-plain' },
    { name: 'Material UI', icon: 'glyphter-material-ui' },
  ],
  backEnd: [
    { name: 'Java', icon: 'devicon-java-plain' },
    { name: 'Python', icon: 'devicon-python-plain' },
    { name: 'Spring Boot', icon: 'glyphter-spring-3' },
    { name: 'SQL', icon: 'devicon-mysql-plain' },
    { name: 'C', icon: 'devicon-c-plain' },
    { name: 'AWS', icon: 'devicon-amazonwebservices-original' },
  ],
  mobile: [
    { name: 'React Native', icon: 'devicon-react-original' },
    { name: 'Xamarin.Forms', icon: 'glyphter-xamarin' },
    { name: 'Android', icon: 'devicon-android-plain' },
  ],
};

function SkillsCard(props) {
  const classes = useStyles();
  const { title, category, itemStyle } = props;

  return (
    <Card className={classes.skillsCard}>
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <div className={classes.listsContainer}>
        <List className={classes.list}>
          {category
            .slice(0, Math.ceil(category.length / 2))
            .map((skill, index) => (
              <ListItem className={itemStyle} key={index}>
                <ListItemIcon>
                  <i className={skill.icon}></i>
                </ListItemIcon>
                <ListItemText primary={skill.name} />
              </ListItem>
            ))}
        </List>
        <List className={classes.list}>
          {category
            .slice(Math.ceil(category.length / 2), category.length)
            .map((skill, index) => (
              <ListItem className={itemStyle} key={index}>
                <ListItemIcon>
                  <i className={skill.icon}></i>
                </ListItemIcon>
                <ListItemText primary={skill.name} />
              </ListItem>
            ))}
        </List>
      </div>
    </Card>
  );
}

export default function Skills() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <SkillsCard
        title="Front End"
        category={skills.frontEnd}
        itemStyle={classes.listItemFE}
      />
      <SkillsCard
        title="Back End"
        category={skills.backEnd}
        itemStyle={classes.listItemBE}
      />
      <div className={classes.break}></div>
      <SkillsCard
        title="Mobile"
        category={skills.mobile}
        itemStyle={classes.listItemM}
      />
    </React.Fragment>
  );
}
