import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuIcon from '@material-ui/icons/Menu';
import '../App.css';

import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  appbar: {
    backgroundColor: '#00b4b7'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'

    // borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbarTitle: {
    flex: 1
  },
  tabs: {
    marginRight: 'auto'
  },
  titleContainer: {
    margin: '1em'
  }
}));

const tabs = ['/about', '/projects', '/skills', '/contact'];
const resumeUrl =
  'https://s3.us-east-2.amazonaws.com/owenkoehler.com/Koehler-Owen+Resume.pdf';

function NavDrawer(props) {
  const classes = useStyles();
  const { drawerIsOpen, toggleDrawer, anchor } = props;
  const { linkedin, github } = props.contact;

  return (
    <Drawer open={drawerIsOpen} onClose={toggleDrawer} anchor={anchor}>
      <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
        <div className={classes.titleContainer}>
          <Logo />
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={'My Resume'}
              onClick={() => window.open(resumeUrl, '_blank')}
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <ListItemText
              primary={'LinkedIn'}
              onClick={() => window.open(linkedin, '_blank')}
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <GitHubIcon />
            </ListItemIcon>
            <ListItemText
              primary={'GitHub'}
              onClick={() => window.open(github, '_blank')}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

function MediumHeader(props) {
  const classes = useStyles();
  const { contact, history } = props;
  const [tabSelection, setTabSelection] = useState(0);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  function handleTabClick(tab, page) {
    setTabSelection(tab);
    props.history.push(page);
    // setTriggerLogoUpdate(!triggerLogoUpdate);
  }

  function toggleDrawer() {
    setDrawerIsOpen(isOpen => !isOpen);
  }

  useEffect(() => {
    let tab = tabs.indexOf(props.location.pathname);
    if (tab > -1) {
      setTabSelection(tab);
    } else {
      setTabSelection(0);
    }
  }, [props.location.pathname]);

  return (
    <React.Fragment>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Tabs
            className={classes.tabs}
            value={tabSelection}
            // variant="scrollable"
            variant="fullWidth"
            // scrollButtons="off"
            // scrollButtons="on"
            textColor="secondary"
          >
            <Tab
              label="About"
              size="small"
              onClick={() => handleTabClick(0, '/about')}
            />
            <Tab
              label="Projects"
              size="small"
              onClick={() => handleTabClick(1, '/projects')}
            />
            <Tab
              label="Skills"
              size="small"
              onClick={() => handleTabClick(2, '/skills')}
            />
            <Tab
              label="Contact"
              size="small"
              onClick={() => handleTabClick(3, '/contact')}
            />
          </Tabs>
          <IconButton className={classes.menuButton} onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <NavDrawer
        drawerIsOpen={drawerIsOpen}
        toggleDrawer={toggleDrawer}
        contact={contact}
        history={history}
        anchor={'right'}
      />
    </React.Fragment>
  );
}

export default withRouter(MediumHeader);
