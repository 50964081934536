import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProjectCard from '../components/ProjectCard';

const projectsData = [
  {
    title: 'Weather App',
    technologies: 'React / Redux / Python / MySQL',
    mobile: false,
    image: 'weather.png',
    animation: 'weather-anim4.gif',
    playing: false,
    frontEndCode: 'https://github.com/OwenKoehler/weather-react',
    backEndCode: 'https://github.com/OwenKoehler/zipcode-api',
    description: `The five-day weather forecast for the specified zipcode can be viewed by the hour. The search is done using OpenWeather's API. 'Favorite' zipcodes can be saved, to view their forecast quickly. This is done using a custom Python REST API and MySQL database.`
  },
  {
    title: 'Employee Management',
    technologies: 'React / Spring Boot / MySQL',
    mobile: false,
    image: 'res-mgmt.png',
    animation: 'res-mgmt.gif',
    playing: false,
    frontEndCode: 'https://github.com/OwenKoehler/resource-mgmt-react',
    backEndCode: 'https://github.com/ryannHippen/springProject',
    description:
      'A multi-table CRUD app. Employees are associated with profiles, evaluations, and resumes. Data is sent and received using a Spring Boot REST API.'
  },
  {
    title: 'Tip Calculator',
    technologies: 'React Native',
    mobile: true,
    image: 'tip_calculator.jpg',
    animation: 'tip_calculator.gif',
    playing: false,
    frontEndCode: 'https://github.com/OwenKoehler/TipCalculator',
    backEndCode: null,
    description:
      'A simple tip calculator mobile app. The tip percentage is selected with radio buttons and the total is displayed to the user.'
  }
];

export default function Projects(props) {
  const classes = useStyles();
  const [projects, setProjects] = useState(projectsData);

  function handleCardActionAreaClick(project) {
    let projectCopy = project;
    projectCopy.playing = !projectCopy.playing;
    const projectsCopy = projects.map(p => {
      if (p.title === projectCopy.title) {
        return projectCopy;
      } else {
        p.playing = false;
        return p;
      }
    });
    setProjects(projectsCopy);
  }

  return (
    <React.Fragment>
      {/* Render Non-Mobile Projects */}
      {projects.map(
        (project, index) =>
          !project.mobile && (
            <ProjectCard
              project={project}
              key={index}
              handleCardActionAreaClick={handleCardActionAreaClick}
            />
          )
      )}
      <div className={classes.break}></div>
      {/* Render Mobile Projects */}
      {projects.map(
        (project, index) =>
          project.mobile && (
            <ProjectCard
              project={project}
              key={index}
              handleCardActionAreaClick={handleCardActionAreaClick}
            />
          )
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  break: {
    flexBasis: '100%'
  }
}));
