import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import './App.css';
import TabHeader from './components/TabHeader';
import MediumHeader from './components/MediumHeader';
import CompactHeader from './components/CompactHeader';
import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Skills from './pages/Skills';

const appTheme = createMuiTheme({
  palette: createPalette({
    primary: { main: '#00b4b7', contrastText: '#e8f1f2' },
    secondary: { main: '#E31B6D' },
    background: { main: '#1649D6' },
    text: { primary: '#143642', secondary: '#4a525a' }
  })
});
// 1: https://coolors.co/e8f1f2-3a3335-00b7ab-143642-d81e5b
// 2: https://coolors.co/e8f1f2-3a3335-00b4b7-143642-e31b6d
// Tertiary: #5438dc (used for mobile skills card)

const skillsTheme = createMuiTheme({
  primary: { main: '#00b4b7', contrastText: '#e8f1f2' },
  secondary: { main: '#E31B6D' },
  overrides: {
    i: {
      color: '#143642'
    },
    MuiListItem: {
      root: {
        '&:hover *': {
          color: 'white !important'
        }
      }
    }
    // MuiTypography: {
    //   body1: {
    //     fontFamily: `'Roboto Mono', monospace !important`
    //   }
    // }
  }
});

const useStyles = makeStyles(theme => ({
  appContainer: {
    textAlign: 'center',
    height: '100%',
    marginTop: '4em'
  },
  pageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingTop: '1em',
    paddingBottom: '2em'
  }
}));

const contact = {
  name: 'Owen Koehler',
  email: 'owenakoehler@gmail.com',
  phone: '(614) 822 - 6288',
  linkedin: 'https://www.linkedin.com/in/owen-koehler-21a409148',
  github: 'https://github.com/OwenKoehler/'
};

function App() {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  });

  return (
    <Router>
      <ThemeProvider theme={appTheme}>
        <div className={classes.appContainer}>
          {windowWidth > 900 ? (
            <TabHeader contact={contact} />
          ) : windowWidth > 366 ? (
            <MediumHeader contact={contact} />
          ) : (
            <CompactHeader contact={contact} />
          )}
          <div className={classes.pageContainer}>
            <Switch>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/contact">
                <Contact contact={contact} />
              </Route>
              <Route exact path="/projects">
                <Projects />
              </Route>
              <Route exact path="/skills">
                <ThemeProvider theme={skillsTheme}>
                  <Skills />
                </ThemeProvider>
              </Route>
              <Redirect to="/about" />
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
