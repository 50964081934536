import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import '../App.css';

import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    // backgroundColor: '#00b4b7'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '64px'
  },
  toolbarTitle: {
    flex: 1
  },
  menuButton: {},
  tabs: {
    marginRight: 'auto'
  },
  titleContainer: {
    margin: '1em'
  },
  stepper: {
    flex: 1,
    flexDirection: 'row'
  },
  stepperButton: {
    color: theme.palette.primary.contrastText
  },
  stepperTitle: {
    display: 'inline-block',
    margin: '0em 1em',
    fontWeight: 'bold'
  },
  dots: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: '0'
    // active background color set in App.css
  }
}));

const tabs = [
  { title: 'About', path: '/about' },
  { title: 'Projects', path: '/projects' },
  { title: 'Skills', path: '/skills' },
  { title: 'Contact', path: '/contact' }
];
const tabPaths = ['/about', '/projects', '/skills', '/contact'];

const resumeUrl =
  'https://s3.us-east-2.amazonaws.com/owenkoehler.com/Koehler-Owen+Resume.pdf';

function NavDrawer(props) {
  const classes = useStyles();
  const { drawerIsOpen, toggleDrawer, history, anchor } = props;
  const { linkedin, github } = props.contact;

  function handleTabClick(tab, page) {
    history.push(page);
  }

  return (
    <Drawer open={drawerIsOpen} onClose={toggleDrawer} anchor={anchor}>
      <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
        <div className={classes.titleContainer}>
          <Logo />
          {/* <Typography variant="h6">Owen Koehler</Typography> */}
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText
              primary={'About'}
              onClick={() => handleTabClick(0, '/about')}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={'Projects'}
              onClick={() => handleTabClick(1, '/projects')}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={'Skills'}
              onClick={() => handleTabClick(2, '/skills')}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={'Contact'}
              onClick={() => handleTabClick(3, '/contact')}
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={'My Resume'}
              onClick={() => window.open(resumeUrl, '_blank')}
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <ListItemText
              primary={'LinkedIn'}
              onClick={() => window.open(linkedin, '_blank')}
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <GitHubIcon />
            </ListItemIcon>
            <ListItemText
              primary={'GitHub'}
              onClick={() => window.open(github, '_blank')}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

function NavStepper(props) {
  const classes = useStyles();
  const { handleNext, handleBack, activeStep } = props;

  return (
    <div className={classes.stepper}>
      <Button
        className={classes.stepperButton}
        size="small"
        onClick={() => handleBack(activeStep)}
        disabled={activeStep === 0}
      >
        <KeyboardArrowLeft />
        Back
      </Button>
      <Typography className={classes.stepperTitle} component="h1">
        {tabs[activeStep].title.toUpperCase()}
      </Typography>
      <Button
        className={classes.stepperButton}
        size="small"
        onClick={() => handleNext(activeStep)}
        disabled={activeStep === 3}
      >
        Next
        <KeyboardArrowRight />
      </Button>

      <MobileStepper
        variant="dots"
        steps={4}
        position="static"
        activeStep={activeStep}
        className={classes.dots}
      />
    </div>
  );
}

function CompactHeader(props) {
  const classes = useStyles();
  const { contact, history } = props;
  const [tabSelection, setTabSelection] = useState(0);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  // const [visibleTabs] = useState(0);
  // const [activeStep, setActiveStep] = React.useState(0);

  function toggleDrawer() {
    setDrawerIsOpen(isOpen => !isOpen);
  }

  useEffect(() => {
    let tab = tabPaths.indexOf(props.location.pathname);
    if (tab > -1) {
      setTabSelection(tab);
    } else {
      setTabSelection(0);
    }
  }, [props.location.pathname]);

  const handleNext = prevTab => {
    setTabSelection(prevTabSelection => prevTabSelection + 1);
    props.history.push(tabs[prevTab + 1].path);
  };

  const handleBack = prevTab => {
    setTabSelection(prevTabSelection => prevTabSelection - 1);
    props.history.push(tabs[prevTab - 1].path);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <NavStepper
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={tabSelection}
          />
          <IconButton className={classes.menuButton} onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <NavDrawer
        drawerIsOpen={drawerIsOpen}
        toggleDrawer={toggleDrawer}
        contact={contact}
        history={history}
        anchor={'right'}
      />
    </div>
  );
}

export default withRouter(CompactHeader);
