import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  aboutContainer: {
    padding: '3em 2em',
    margin: 'auto',
    marginTop: '8em'
  },
  aboutText: {
    marginTop: '0.5em',
    marginBottom: '0.5em'
  },
  aboutTextInline: {
    display: 'inline-block',
    marginTop: '0.5em',
    marginBottom: '0.5em'
  },
  emphasizeTextInline: {
    display: 'inline-block',
    fontWeight: 'bold'
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.secondary.main
  }
}));

const about = {
  line1: `Thanks for visiting!`,
  line2: `My name is`,
  line3: ` Owen Koehler `,
  line4: `and I'm a Full-Stack Software Engineer.`,
  line5: `Take a look at some of my previous work.`,
  line6: `This website was built with React, hosted with AWS, and is Mobile-Friendly!`
  // line1: 'I graduated with a B.S. in Computer Science and Engineering from The Ohio State University in August of 2019.',
  // line2: 'Since then I have been working as a Software Engineering Consultant and continuing to expand my skillset.'
};

function About(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.aboutContainer}>
      <Typography
        component='p'
        variant='body1'
        color='inherit'
        align='center'
        className={classes.aboutText}
      >
        {about.line1}
      </Typography>


      <Typography
        component='p'
        variant='body1'
        color='inherit'
        align='center'
        className={classes.aboutTextInline}
      >
        {about.line2}
      </Typography>
      {' '}
      <Typography
        component='p'
        variant='body1'
        color='inherit'
        align='center'
        className={classes.emphasizeTextInline}
      >
        {about.line3}
      </Typography>
      {' '}
      <Typography
        component='p'
        variant='body1'
        color='inherit'
        // align='center'
        className={classes.aboutTextInline}
      >
        {about.line4}
      </Typography>


      <Typography
        component='p'
        variant='body1'
        color='inherit'
        // align='center'
        className={classes.aboutText}
      >
        <Link onClick={() => props.history.push('/projects')} className={classes.link}>{about.line5}</Link>
      </Typography>
      {' '}
      <Typography
        component='p'
        variant='body1'
        color='inherit'
        // align='center'
        className={classes.aboutTextInline}
      >
        {about.line6}
      </Typography>
    </Paper>
  );
}

export default withRouter(About);
