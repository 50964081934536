import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import DescriptionIcon from '@material-ui/icons/Description';
import '../App.css';

import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  appbar: {
    backgroundColor: '#00b4b7'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'

    // borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbarTitle: {
    flex: 1
  },
  tabs: {
    marginRight: 'auto'
  }
}));

const tabs = ['/about', '/projects', '/skills', '/contact'];
const resumeUrl =
  'https://s3.us-east-2.amazonaws.com/owenkoehler.com/Koehler-Owen+Resume.pdf';

function TabHeader(props) {
  const classes = useStyles();
  const { linkedin, github } = props.contact;
  const [tabSelection, setTabSelection] = useState(0);
  const [triggerLogoUpdate, setTriggerLogoUpdate] = useState(false);

  function handleTabClick(tab, page) {
    setTabSelection(tab);
    props.history.push(page);
    // setTriggerLogoUpdate(!triggerLogoUpdate);
  }

  useEffect(() => {
    let tab = tabs.indexOf(props.location.pathname);
    if (tab > -1) {
      setTabSelection(tab);
    } else {
      setTabSelection(0);
    }
    setTriggerLogoUpdate(t => !t);
  }, [props.location.pathname]);

  return (
    <React.Fragment>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Logo triggerUpdate={triggerLogoUpdate} />
          <Tabs
            className={classes.tabs}
            value={tabSelection}
            textColor="secondary"
          >
            <Tab
              label="About"
              size="small"
              onClick={() => handleTabClick(0, '/about')}
            />
            <Tab
              label="Projects"
              size="small"
              onClick={() => handleTabClick(1, '/projects')}
            />
            <Tab
              label="Skills"
              size="small"
              onClick={() => handleTabClick(2, '/skills')}
            />
            <Tab
              label="Contact"
              size="small"
              onClick={() => handleTabClick(3, '/contact')}
            />
          </Tabs>
          <div>
            <IconButton
              title={'Resume'}
              onClick={() => window.open(resumeUrl, '_blank')}
            >
              <DescriptionIcon />
            </IconButton>
            <IconButton
              title={'LinkedIn'}
              onClick={() => window.open(linkedin, '_blank')}
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              title={'GitHub'}
              onClick={() => window.open(github, '_blank')}
            >
              <GitHubIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default withRouter(TabHeader);
